.content-info {
	background:#033D85;
	color:#fff;
	padding-top:40px;
	padding-bottom:20px;
	.logo {
		width:120px;
		top:-45px;
	}
	.nav-footer {
		float:right;
		ul {
			li {
				display:inline-block;
				margin-right:38px;
			}
			li:last-child {
				margin-right:0;
			}
			li a {
				font-size:17px;
				font-family: 'DINCondBold';
				color:#fff;
				line-height:24px;
				text-transform:uppercase;
				span {
					display:block;
				}
			}
			li a:hover {
				text-decoration:none;
			}
		}
		.nav {
			display:inline-block;
			text-align:left;
		}
	}
	.social {
		ul {
			margin:0;
			padding:0;
			li {
				display:inline-block;
				margin-right:10px;
			}
			li:last-child {
				margin-right:0;
			}
			.fb-icon {
				width:22px;
				height:22px;
				display:block;
				background:url(../images/facebook.png);
				text-indent:-9999px;
			}
			.tw-icon {
				width:22px;
				height:22px;
				display:block;
				background:url(../images/twitter.png);
				text-indent:-9999px;
			}
			.in-icon {
				width:22px;
				height:22px;
				display:block;
				background:url(../images/instagram.png);
				text-indent:-9999px;
			}
		}

	}
	.copyright {
		font-size:14px;
		text-align:center;
		padding-top:120px;
	}
}

