.s-block {
  padding-top: 125px;
  padding-bottom: 70px;
}

.cta-block {
  padding-top: 200px;
  padding-bottom: 150px;
}

.menu-rows {
  padding-top: 60px;
  img {
    margin-bottom: 20px;
  }

  p {
    font-size: 24px;
    color: #174d8f;
    line-height: 30px;
  }
}

.boxed-heading {
  border: solid 1px #A30F2C;
  display: inline-block;
  padding: 30px 35px;
  margin-bottom: 60px;
  h2 {
    font-family: 'DINCondBold';
    font-size: 44px;
    color: #A30F2C;
    text-transform: uppercase;
    margin: 0;
    span {
      color: #174d8f;
    }
  }
}

.towers {
  background: url(../images/towers-bg.png) no-repeat center bottom #F3F1E9;
}

.real-rows {
  .r-inner {
    position: relative;
    background: #fff;
    padding-top: 60px;
    border-radius: 15px;
    min-height: 900px;
    h3 {
      font-family: 'DINCondBold';
      font-size: 45px;
      color: #174d8f;
      min-height: 98px;
      margin-bottom: 45px;
    }

    p {
      font-size: 24px;
      color: #666666;
      line-height: 30px;
      padding: 0 5px;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-top: 60px;
    }

    .stars {
      position: relative;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
}

.our-distributors {
  h3 {
    font-family: 'DINCondBold';
    font-size: 36px;
    color: #969696;
    margin-bottom: 30px;
  }

  .owl-item {
    padding: 30px;
  }

  .owl-theme .owl-nav [class*=owl-] {
    background: #fff;
    padding: 0;
  }

  .owl-prev {
    position: absolute;
    left: -5px;
    top: 65px;
  }

  .owl-next {
    position: absolute;
    right: -5px;
    top: 65px;
  }

  .owl-prev,
  .owl-next {
    display: inline-block;
  }

  .c-left {
    width: 26px;
    height: 26px;
    background: url(../images/c-left.png);
  }

  .c-right {
    width: 26px;
    height: 26px;
    background: url(../images/c-right.png);
  }
}

#cta {
  font-family: 'DINCondBold';
  font-size: 24px;
  color: #fff;
  line-height: 36px;
  .btn {
    font-family: 'DINCond';
    margin-top: 55px;
  }
}

.banner-img {
  min-height: 400px;
  h1 {
    background: rgba(243,241,234,0.67);
    font-family: 'DINCondBold';
    font-size: 60px;
    color: #033e85;
    padding: 20px 40px;
    border-radius:10px;
  }

  .h-caption {
    max-width: 925px;
    position: relative;
    top: 200px;
    text-align: center;
    margin: 0 auto;
  }
}

.range-filter {
  .filter-label {
    font-family: 'DINCondBold';
  }
  position: relative;
  color: #174d8f;
  margin: 55px 0 80px 0;
  &>ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    position: relative;
    &>li {
      list-style-type: none;
      display: inline-block;
      background: #174d8f;
      color: #fff;
      margin-left: 15px;
      padding: 15px 60px;
      border-radius: 12px;
      cursor: pointer;
      position: relative;

      @media screen and (max-width: 768px) {
        font-size: 18px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 7px 25px;
        line-height: 34px;
        position: initial;
      }
      &:hover {
        background: #A61E36;
      }
    }
  }

  .filter-reset {
    cursor: pointer;
    margin-left: 20px;
  }
}

.range-select {
  .col-12 {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

  .range-item {
    position: relative;
    margin-bottom: 15px;
    img {
      border-radius: 12px;
    }

    .range-caption {
      /*opacity: 0;*/
      position: absolute;
      top: 40px;
      left: 0;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 15px 20px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      max-width: 250px;
      /*transition: all 0.6s ease 0s;*/
      h3 {
        font-family: 'DINCondBold';
        font-size: 30px;
        color: #a51e36;
        text-transform: uppercase;
        margin: 0;
      }
    }

    .btn-primary {
      opacity: 0;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 25px;
      font-size: 19px !important;
      text-transform: uppercase;
      padding: 15px 75px;
      transition: all 0.6s ease 0s;
    }
  }

  .range-item:hover {
    .range-caption {
      opacity: 1;
    }

    .btn-primary {
      opacity: 1;
    }
  }
}


@media (max-width:480px) {
  body.home #hero .btn {
    margin-top: 4px!important;
  }
}
@media (max-width:575px) {
  body.home #hero .btn {
    margin-top: 10px!important;
  }
}
@media screen and (max-width: 768px) {
  body.home .container-fluid .menu-rows .row {
    display:block;
  }
  body.home .container-fluid .real-rows .row {
    display:block;
  }
  body.home footer .container-fluid .row {
    display:block;
  }
}


.range-filter-submenu, .custom-range-menu>ul {
  position: absolute;
  left: 0;
  padding: 15px 35px;
  top: 100%;
  border-radius: 12px;
  background: #A61E36;
  margin-top: 5px;
  list-style-type: none;
  z-index: 10;
  display: none;
  width: 560px;
  max-width: 85vw;

  @media screen and (max-width: 768px) {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  li {
    width: 50%;
    float: left;
    &.reset-button {
      position: absolute;
      bottom: 0;
      right: 8px;
      width: 24px;
      a {
        &:before,
        &:after {
          content: none;
        }

        width: 24px;
        height: 24px;
        display: inline-block;
        background-image: url('../images/filter-icon-white.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    a {
      color: #fff;
      position: relative;
      padding-left: 1.2em;
      &:before {
        position: absolute;
        content: '';
        width: 0.7em;
        height: 0.7em;
        display: inline-block;
        background: #fff;
        box-shadow: inset 0px 1px 3px 0px #808080;
        border-radius: 3px;
        bottom: 5px;
        left: 0;
      }

      &:after {
        transition: all 0.25s;
        position: absolute;
        content: "";
        display: block;
        width: 5px;
        height: 12px;
        border: solid #565353;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 9px;
        left: 6px;
        opacity: 0;
      }

      &.mixitup-control-active:after {
        opacity: 1;
      }
    }
    &.current-cat a::after {
      opacity: 1;
    }
    

    @media screen and (max-width: 768px) {
      width: 48%;
      padding: 0 !important;
      margin: 0;
      float: left;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
      text-align: center;
    }
  }
}

.address-block {
  margin-top: 50px;
  h4 {
    font-family: 'DINCondBold';
    font-size: 48px;
    color: #033e85;
	text-transform: uppercase; 
  }
  p {
    font-family: 'DINCond';
    color: #393939;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 1.2;
  }
}

.range-content {
  padding: 80px 0 120px 0;
  .intro {
    font-size: 24px;
    color: #8e8e8e;
    line-height: 34px;
    padding-bottom: 60px;
  }
}

.distributor {

  &-select {
    &>a {
      @extend .wtb-filter-btn;
      margin-top: 20px;
      margin-bottom: 50px;
      font-size: 23px !important;
      text-transform: uppercase;
      background: #174d8f;
      padding: 9px 75px 9px 89px !important;
      width: 100%;
      text-align: center;
      transition: all 0.25s;
      margin-left: 0 !important;

      @media screen and (max-width: 1200px) {
        padding: 9px 50px 9px 37px!important
      }

      @media screen and (min-width: 576px) and (max-width: 768px) {
        padding: 9px 44px 9px 0px !important;
        font-size: 15px !important;
      }

      &.__open {
        margin-bottom: 0;
      }
    }

    &-list {
      @extend .wtb-filter-list;
      position: relative !important;
      margin-bottom: 5px !important;
      max-width: 100% !important;

      @media screen and (min-width: 576px) and (max-width: 768px) {
        column-count: 1 !important;
      }

      a {
        @media screen and (max-width: 1200px) {
          padding: 4px 3px 4px 6px !important;
          text-align: left;
          font-size: 14px;
        }


        &.__active {
          background: #af3848;
          text-decoration: none;
        }
      }
    }
  }
}

.js-distributor-btn {
  display: none;
  transition: none;
}


@media screen and (max-width: 576px) {
  .single-range {
    .ingredients {
      order: 2;
    }
    .nutritional {
      order: 1;
    }
    .distributor {
      order: 3;
    }
  }
}

.single-recipes {
  .h-caption .text-danger p {
    font-weight: bold;
  }
}