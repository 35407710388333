.banner {
  position: relative;
  height: 60px;
  background: #033D85;
  .nav {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .nav-primary {
    text-transform: uppercase;
    float: right;
    position: absolute;
    top: 10px;
    right: 0;
    padding: 0 64px;
    width: 100%;
    .nav-right {
      @media only screen and (min-width: 961px) {
        float: right;
      }
    }
    ul {
      li {
        margin-right: 15px;
      }
      li:last-child {
        margin-right: 0;
      }
      li a {
        font-family: 'DINCondBold';
        font-size: 23px;
        color: #fff;
        /*padding: 60px 15px;*/
        padding: 15px 15px 20px 15px;
      }
      li a:hover {
        background: #fff;
        color: #033D85;
        text-decoration: none;
      }
    }
    ul.sub-menu {
      position: absolute;
      display: none;
      top: 50px;
      width: 100%;
      background: rgba(255, 255, 255, 0.9);
      margin: 0;
      padding: 30px 15px;
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-rule: 2px outset #a51e36;
      -moz-column-rule: 2px outset #a51e36;
      column-rule: 2px outset #a51e36;
      z-index: 2;
      li {
        list-style-type: none;
        /*margin-right: 0;*/
        margin-right:15px;
        width:200px;
      }
      li a {
        font-size: 15px;
        color: #174d8f;
        padding: 5px;
      }
      li a:hover {
        color: #a51e36;
        background: transparent;
      }
    }
    ul li:hover ul {
      @media only screen and (min-width: 961px) {
        display: block;
      }
    }
  }
  .brand {
    position: relative;
    top: 14px;
    padding-left: 10px;
  }
}

.nav-primary ul li:hover>a {
  background: #fff;
  color: #033D85;
}

#hero {
  min-height: 780px;
  h1 {
    background: rgba(243,241,234,0.67);
    font-family: 'DINCondBold';
    font-size: 60px;
    color: #033e85;
    border-radius:10px;
    padding: 20px 40px;
  }
  .h-caption {
    max-width: 965px;
    position: relative;
    top: 270px;
    text-align: center;
    margin: 0 auto;
  }
  .btn {
    font-family: 'DINCondBold';
    margin-top: 60px;
  }
}

.page-template-template-home .logo {
  max-width: 237px;
}

.logo {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 15%;
  min-width: 120px;
  max-width: 200px;
  height: auto;
  margin-top: -55px;
  z-index: 1;
}

.open-mobile-nav {
  width: 30px;
  height: 23px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  position: absolute;
  top: 17px;
  right: 20px;

  @media only screen and (min-width: 961px) {
    display: none;
  }

  span {
    display: block;
    position: absolute;
    height: 4.5px;
    width: 100%;
    background: #fff;
    border-radius: 4.5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
    }

    &:nth-child(4) {
      top: 18px;
    }
  }

  &.__open {
    span {
      &:nth-child(1) {
        top: 9px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 9px;
        width: 0%;
        left: 50%;
      }
    }
  }
}
