$news-small: 768px;
.news-wrapper{
  padding: 32px 0 100px 0;
  @media screen and (max-width: $news-small) {
    padding: 35px 0 100px;
  }
}
.news-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  & > div {
    display: grid;
    grid-template-rows: auto 1fr;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    background: #f2f1eb;
  }
  .news-image{
    position: relative;
    height: 420px; 
    background-position: bottom left;
    background-size: cover;
    background-repeat: no-repeat;
    
    @media screen and (min-width: $news-small) and (max-width: 960px) {
      height: 350px;
    }
    @media screen and (max-width: $news-small) {
      height: 250px;
    }
    .overlay{
      background-color: rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  h3{
    float: left;
    position: absolute;
    top: 30px;
    left: 30px;
    max-width: calc(100% - 60px);
    margin: 0;
    background: rgba(255,255,255,0.8);
    border-radius: 15px;
    > a{
      font-size: 28px;
      padding: 10px 15px;
      display: block;
      text-transform: uppercase;
      font-family: 'DINCondBold', sans-serif;
      color: $brand-secondary;
    }
    @media screen and (max-width: 960px){
      top: 15px;
      left: 15px;
      border-radius: 5px;
      a{
        font-size: 26px;
      }
    }
    @media screen and (max-width: 768px){
      a{
        font-size: 18px;
        padding: 6px 10px;
      }
    }
  }
  .news-caption{
    display: grid;
    grid-template-rows: auto 1fr auto;
    background:#f2f1eb;
    padding: 20px 40px;
    color: #666666;
    @media screen and (max-width: 575px) {
      padding: 10px 20px;
      font-size: 16px;
    }
  }
  .news-date{
    font-size: 18px;
    float: right;
    font-family: 'DINCondBold', Arial, Helvetica, sans-serif;
    color: $brand-primary;
    justify-self: end;
    margin-bottom: 10px;
    @media screen and (max-width: 575px) {
      font-size: 16px;
      img{
        width: 22px;
      }
    }
  }
  .news-footer{
    margin-top: 10px;
  }
}
.news-read-more{
  float: right;
  padding: 10px 70px;
  @media screen and (max-width: 575px) {
    padding: 10px 20px;
    border-radius: 9px;
    font-size: 16px !important;
    margin-right: -10px;
  }
  font-size: 20px !important;
  font-family: 'DINCondBold', sans-serif;
}
.range-filter.news-filter{
  text-align: center;
  @media screen and (min-width: 768px){
    float: right;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    & > ul{
      width: 80%;
      & > li{
        width: calc(50% - 10px);
        padding: 3px 5px;
      }
    }
  }
  .filter-label{
    text-align: left;
  }
}
.news-categories{
  float: left;
  margin: 5px 0;
  @media screen and (max-width: $news-small) {
    padding: 0;
  }
  .catetgory{
    display: block;
  }
}
.news-category{
  padding: 6px 20px;
  min-width: 100px;
  font-size: 18px !important;
  background: $brand-primary;
  color: white;
  text-transform: none;
  font-family: 'DINCondBold', sans-serif;
  &:hover{
    background: $brand-secondary;
  }
  @media screen and (max-width: 575px) {
    padding: 2px 10px;
    min-width: 0px;
    font-size: 16px !important;
  }
}
.category-label{
  font-family: 'DINCondBold', Arial, Helvetica, sans-serif;
  color: $brand-primary;
  font-size: 20px;
  margin-right: 20px;
  @media screen and (max-width: $news-small) {
    font-size: 16px;
    margin-right: 5px;
  }
}
/* RESPONSIVE */
@media screen and (max-width: $news-small) {
  .news-grid {
    grid-template-columns: 1fr;
  }
}
/* IE 9 - 11 STYLES */
@media screen and (min-width:0\0) {
  .news-grid, .news-grid > div, .news-caption{
    display: block !important;
  }
  .news-grid > div{
    float: left;
    margin: 15px;
    width: calc(50% - 30px);
    padding-bottom: 10px;
  }
}
/* NO GRID? STYLES */
@supports not (display: grid) {
  .news-grid > div{
    margin: 20px 10px;
  }
}