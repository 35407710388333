@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/wtb";
@import "layouts/news";
@import "layouts/news-item";

.main {
  padding: 0;
}


@font-face {
  font-family: 'DINCondBold';
  src: url('../fonts/DINCond-Bold.eot');
  src: url('../fonts/DINCond-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DINCond-Bold.woff2') format('woff2'),
    url('../fonts/DINCond-Bold.woff') format('woff'),
    url('../fonts/DINCond-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DINCond';
  src: url('../fonts/DINCond-Bold.eot');
  src: url('../fonts/DINCond-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINCond-Bold.woff2') format('woff2'),
  url('../fonts/DINCond-Bold.woff') format('woff'),
  url('../fonts/DINCond-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face { 
  font-family: 'DINCond';
  src: url('../fonts/DINCond-Regular.eot');
  src: url('../fonts/DINCond-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DINCond-Regular.woff2') format('woff2'),
    url('../fonts/DINCond-Regular.woff') format('woff'),
    url('../fonts/DINCond-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('../fonts/CenturyGothic-Bold.eot');
  src: url('../fonts/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CenturyGothic-Bold.woff2') format('woff2'),
  url('../fonts/CenturyGothic-Bold.woff') format('woff'),
  url('../fonts/CenturyGothic-Boldd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@media only screen and (max-width: 1100px) {
  .banner .nav-primary {
    top: 14px;
  }
  .banner .nav-primary ul li a {
    font-size: 20px;
    padding: 60px 10px;
  }
  .banner .nav-primary ul.sub-menu {
    top: 46px;
  }
  .banner .nav-primary ul.sub-menu li a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 998px) {
  .real-rows .r-inner h3 {
    min-height: 147px;
  }
}

@media screen and (min-width: 961px) {
	.menu-separator{
		display: none;
	}
}

@media only screen and (max-width: 960px) {

  .banner {
    .nav-primary {
      display: none;
      top: 100% !important;
      z-index: 1000;
      background: #fff;
      padding: 0 !important;
      width: 100%;

      ul {

        display: block;

        li {
          margin: 0 !important;

          &.menu-item-has-children {
            a {
              pointer-events: none;
            }
          }

          .sub-menu {
            position: relative;
            top: 0;
            column-count: 1;
            padding: 0;
            background: #f2f2f2;

            li {
              width:100%;
              border-bottom: 1px solid #a30f2c;

              &.menu-separator{
              	a{
              		color: transparent !important;
              	}
              }

              &:last-of-type {
                border-bottom: none;
              }

              a {
                border-bottom: none;
                pointer-events: all;
                text-align: center;
                text-transform: none;
                font-size: 20px;
              }
            }
          }

          &:hover {
            ul {
              display: none;
            }
          }

          &>a {
            color: #174d8f !important;
            text-align: center;
            width: 100% !important;
            display: block;
            padding: 10px !important;
            margin: 0 !important;
            border-bottom: 2px solid #e4e4e4;
          }
        }
      }
    }
  }

  header.banner>.container-fluid {
    position: initial;
  }
}

@media only screen and (max-width: 640px) {
  .required {
    display: none;
  }
  .required-sm {
    display: block;
    float: left;
  }
}

@include media-breakpoint-down(md) {
  .m-excerpt {
    margin-bottom: 45px;
  }

  .social {
    text-align: center;
  }

  .content-info .nav-footer {
    float: none;
    text-align: center;
  }

  .social {
    position: relative;
    top: 75px;
  }

  .content-info .nav-footer {
    position: relative;
    top: 85px;
  }
}

@include media-breakpoint-down(sm) {

	.real-rows .r-inner {

	    	display: flex;
	    	flex-direction: column-reverse;
    
	    img {
	    	border-top-left-radius:10px;
	    	border-top-right-radius:10px;
	    	margin-top:0;
	    	margin-bottom:20px;
	    }
	}
	.real-rows .r-inner  {
    min-height:0 !important;
    padding-top: 0;
		h3 {
			min-height:0;
			margin-bottom:20px;
		}
		img {
			position:relative;
		}
		p {
			margin-bottom:45px;
		}
	}

	.our-distributors .owl-prev,
	.our-distributors .owl-next {
		top:75px;
	}

	.range-filter {
		span.filter-label {
			display:block;
			margin-bottom:15px;
		}

		ul {

		}

		li {
			font-size:18px;
			margin-left:5px;
			margin-bottom:5px;
			padding:7px 25px;
		}
	}

	.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
	.gform_wrapper label.gfield_label {
		font-size:24px;
	}

	ul.gfield_checkbox li label {
		font-size:20px !important;
	}

	.gform_button {
    	font-size: 20px!important;
    	padding: 15px 70px;
    	min-height:0;
    	margin-top:40px !important;
	}


	.range-select .range-item .btn-primary,
	.range-select .range-item .range-caption {
		opacity: 1;
	}

}



@include media-breakpoint-down(xs) {
  h2 {
    font-size: 36px;
  }
  #hero {
    min-height: 410px;
    .h-caption {
      top: 100px;
    }
    h1 {
      font-size: 30px;
      line-height: 36px;
    }
    .btn {
      font-size: 20px;
      padding: 15px 50px;
    }
  }
  #cta {
    font-size: 18px;
    line-height: 24px;
    .btn {
      font-size: 20px !important;
      padding: 15px 50px;
      margin-top: 20px;
    }
  }
  .menu-rows {
    padding-top: 30px;
  }
  .s-block {
    padding-top: 35px;
  }
  .lead {
    font-size: 24px;
    line-height: 30px;
  }
  .boxed-heading {
    margin-top: 30px;
    h2 {
      font-size: 22px;
    }
  }

  .real-rows {
    text-align: left;
  }

  .real-rows .r-inner {
    padding-top: 0;
  }

  .real-rows .r-inner h3 {
    font-size: 26px;
  }

  .real-rows .r-inner p {
    font-size: 14.4px;
    line-height: 19.45px;
  }

  .our-distributors .owl-prev {
    left: -5px;
    top: 35%;
  }
  .our-distributors .owl-next {
    right: -5px;
    top: 35%;
  }

  .cta-block {
    padding-top: 65px;
    padding-bottom: 70px;
  }

  .content-info .nav-footer ul li a {
    font-size: 14px;
  }

  .content-info .nav-footer ul li {
    margin-right: 20px;
  }

  .page-content {
    font-size: 20px;
    line-height: 24px;
    h1 {
      font-size: 30px;
    }
  }

  .banner-img {
    min-height: 300px;
    .h-caption {
      top: 100px;
    }
    h1 {
      font-size: 30px;
    }
  }

  .inner-header {
    padding: 75px 0;
  }

  .range-filter {
    margin: 35px 0 20px 0;
  }

  .gform_wrapper textarea.medium {
    min-height: 150px;
  }

  .gform_wrapper hr {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .gfield_checkbox li.gchoice_1_17_1 {
    margin: 30px 0 10px 0;
  }

  .required-sm {
    margin-left: 30px;
  }
}

@media (max-width: 325px) {
  .filter-reset {
    position: absolute;
    left: 85px;
    top: 100px;
  }
}


@media only screen and (max-width: 960px) {
    body.home #hero .btn {
        margin-top: 25px!important;
    }
    .single .banner-img .h-caption {
        padding:15px 30px 15px 30px;
    }
    .single .banner-img .h-caption h1 {
        font-size:40px;
    }
    .real-rows .r-inner {
        min-height: 780px
    }
}

.news-pagination{
  text-align: center;
  margin-top: 60px;
  .page-numbers{
    display: inline-block;
    border-radius: 5px;
    padding: 5px 18px;;
    color: white;
    margin: 0 5px 10px 5px;
    background-color: $brand-secondary;
    font-family: 'DINCondBold', Arial, Helvetica, sans-serif;
    &.dots{
      background-color: $brand-primary;  
    }
    @media screen and (max-width: 575px){
      padding: 8px 17px;
      margin: 0 2px 10px 2px;
      font-size: 16px;
    }
  }
  a.page-numbers{
    background-color: $brand-primary;
    &:hover {
      text-decoration: none;
      background-color: $brand-secondary;
    }
  }
}

/* New form Styles July 18 */
.gform_wrapper .contact-form{
  input, select{
    width: 100% !important;
  }
  .ginput_container{
    margin-top: 0 !important;
  }
  .contact-form-products{
    .ginput_container_checkbox{
      column-count: 3;
      @media screen and (max-width: 768px){
        column-count: unset;
      }
    }
  }
  .gform_fields .gfield{
    width: 33.3333%;
    @media screen and (max-width: 768px){
      width: 100%;
      padding-right: 0;
    }
    float: left;
    clear: none;
    position: relative;
    padding-right: 34px;
    margin-top: 34px;
    &.gfield_error{
      background: transparent;
      padding-right: 30px;
    }
  }

  .width_33{
    width: 33.3333% !important;
    @media screen and (max-width: 768px){
      width: 100% !important;
    }
  }
  .width_66{
    width: 66.6666% !important;
    @media screen and (max-width: 768px){
      width: 100% !important;
    }
  }
  .width_100{
    width: 100% !important;
  }

  .contact-form-switch {
    ul.gfield_radio{
      width: 100%;
    }
    li {
      width: 50%;
      float: left;
      display: block;
    }
    input[type=radio] {
      opacity: 0;
      position: absolute;
      & + label {
        border: 2px solid #174d8f;
        background: transparent;
        color: #666666;
        width: 100%;
        height: 70px;
        max-width: 100%;
        margin: 0;
        padding: 13px 20px;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        @media screen and (max-width: 768px){
          font-size: 22px;
        }
      }
      &:checked + label {
        background: #a40f2c;
        color: white;
      }
    }
  }

  .field_sublabel_below ul.gfield_checkbox li{
    min-height: 50px;
    margin-bottom: 10px;
    @media screen and (max-width: 768px){
      text-align: right;
    }
    label {
      padding-left: 60px;
      color: #393939 !important;
      font-size: 28px !important;
      font-family: 'DINCond', Arial, sans-serif;
      &::after {
        width: 40px;
        height: 40px;
        top: 0;
        background: transparent;
        border: 2px solid #174d8f;
        border-radius: 0;
      }
      @media screen and (max-width: 768px){
        padding-left: 0;
        padding-right: 60px;
        text-align: right;
        &::after {
          left: auto;
          right: 10px;
        }
      }
    }
    input[type="checkbox"]:checked + label:before{
      width: 10px;
      height: 18px;
      border: solid #383838;
      border-width: 0 5px 5px 0;
      left: 15px;
      top: 4px;
    }
    input[type="checkbox"] + label:before{
      @media screen and (max-width: 768px){
        left: auto !important;
        right: 24px;
      }
    }
  }
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
    height: 60px;
    background: transparent;
    border: 2px solid #174d8f;
    border-radius: 0px;
    padding: 10px 25px 0;
  }
  span[class*="ginput"]{
    width: 33% !important;
    @media screen and (max-width: 768px){
      width: 100% !important;
    }
    float: left;
    clear: none;
    position: relative;
    padding-right: 16px !important;
    margin-top: 16px !important;
    min-height: 0;
  }
  .gfield_label,.gfield_label_before_complex{
    color: #393939;
  }
  textarea{
    background: transparent;
    border: 2px solid #174d8f;
  }
  .gform_button.button{
    width: 33.333% !important;
    float: right;
    @media screen and (max-width: 768px){
      width: 100% !important;
    }
  }
  .gfield_description{
    margin-bottom: 15px;
  }
}

.Pc4uGflookup{
  @include button-variant(#ffffff, #00A9A9, #00A9A9);
  border-radius: 10px;
  font-family: 'CenturyGothic';
  font-size: 21px;
  text-transform: uppercase;
  border: 2px solid #00A9A9;
  padding: 8px 20px !important;
  cursor: pointer;
}

.faded-bg-img{
  margin-top: -8px;
  background: #cccccc;
  background-image: url('../images/pale-nyc-bg.jpg');
  background-size: cover;
  background-position: center top;
}

/* Raising label classes and styles */
.gform_wrapper .contact-form {
  
  .label-inner.no-content label,.label-inner-address.no-content label:not(.gfield_label_before_complex){
    top: 22px;
    left: 25px;
    font-size: 28px;
  }
  .label-inner label,.label-inner-address label:not(.gfield_label_before_complex){
    color: #383838;
    position: absolute;
    left: 20px;
    top: 8px;
    display: block;
    z-index: 1;
    font-size: 18px;
    line-height: 14px;
    transition-property: all;
    transition-duration: 0.2s;
  }
  .address_state label{
    position: relative !important;
    top: auto !important;
    left: auto !important;
    font-size: 28px !important;
    margin-bottom: 5px;
  }
  
}

/* Sticky aside styles */
.sticky-aside{
  &.show{
    right: -10px; 
  }
  display: block;
  position: fixed;
  top: 100px;
  right: -150px;
  transition: right 0.3s;
  width: 115px;
  background: #383838;
  overflow: hidden;
  border-radius: 10px;
  z-index: 1;
  a{
    display: block;
    text-align: center;
    color: white;
    padding: 25px 30px 25px 15px;
    font-family: 'DINCondBold', Arial, Helvetica, sans-serif;
    font-size: 24px;
    &:hover{
      background: darken(#383838, 10%);
    }
    &:first-of-type{
      background: #a40f2c;
      &:hover{
        background: darken(#a40f2c, 10%);
      }
    }
    &.envelope-icon{
      img{
        width: 40px;
      }
    }
    &.bagel-icon{
      img{
        width: 51px;
      }
    }
  }
  img{
    display: block;
    margin: auto;
  }
  
  @media screen and (max-width: 768px){
    top: auto;
    bottom: -100px;
    transition: bottom 0.3s;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
    a{
      width: 50%;
      float: left;
      padding: 8px 10px 8px 10px;
      font-size: 18px;
      &.envelope-icon{
        padding: 13px 10px 13px 10px;
        img{
          width: 34px;
        }
      }
      &.bagel-icon{
        img{
          width: 43px;
        }
      }
    }
    img{
      display: inline-block;
      margin-right: 10px;
    }
    &.show{
      bottom: 0;
    }
  }
}