// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_wrapper hr {
	margin-top:70px;
	margin-bottom:70px;
}

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
.gform_wrapper label.gfield_label {
    font-family: 'DINCondBold';
    font-size: 30px;
    color: #a40f2c;
}

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label {
	margin:0 0 6px 0;
}

.gform_wrapper textarea.medium {
	background:#E9EEF4;
	border:none;
	min-height:270px;
}

ul.gfield_checkbox li label {
	color:#033E85 !important;
	font-size:24px !important;
}

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
	height:45px;
	background:#F8EFF1;
	padding:4px;
	border:none;
	border-radius:7px;
}

.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] {
	width:auto;
}

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
	line-height:normal;
	min-height:0;
}

.required-sm,
.required {
	float:right;
	color:#033e85;
	font-family: 'DINCondBold';
	font-size:18px;
}

.required {
	margin-top: -45px;	
}

.required-sm {
	display:none;
}

.gfield_checkbox {
	font-family: 'DINCondBold';
		li {
			position: relative;
			label {
				&:after {
					content:"";
					background:#02336E;
					width:15px;
					height:17px;
					display:block;
					position: absolute;
					left:0;
					top:8px;
					border-radius:3px;
					z-index: 0;
				}
				&:before {
					content:"";
					display:block;
				    position: absolute;
				    width: 5px;
					height: 12px;
				    border: solid #fff;
				    border-width: 0 3px 3px 0;
			    	left: 5px;
					top: 7px;
					transform: rotate(45deg);
					opacity:0;
					z-index:1;
					transition:all 100ms;
					margin-top: 6px;
				}
			}
		}

		li.gchoice_1_17_1 {
			position: relative;
			margin:40px 0 60px 0;
			label {
				&:after {
					content:"";
					background:#838383;
					width:15px;
					height:17px;
					display:block;
					position: absolute;
					left:0;
					top:8px;
					border-radius:3px;
					z-index: 0;
				}
				&:before {
					content:"";
					display:block;
				    position: absolute;
				    width: 5px;
					height: 12px;
				    border: solid #fff;
				    border-width: 0 3px 3px 0;
			    	left: 5px;
					top: 7px;
					transform: rotate(45deg);
					opacity:0;
					z-index:1;
					transition:all 100ms;
					margin-top: 6px;
				}
			}
		}

	}

	.gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox li, .gform_wrapper li.gfield.gf_list_4col ul.gfield_radio li {
		padding-left: 0px !important;
	}

	.gform_wrapper .field_sublabel_below ul.gfield_checkbox li label {
		color:#838383;
		padding-left: 30px;
	}

	input[type="checkbox"] {
		opacity:0;
		outline:none;
		z-index:100;
		width:27px;
		height:27px;
		top:0;
		left:0;
		position: absolute;
		appearance: none;
		cursor: pointer;
    + label {
      cursor: pointer;
    }
		&:checked + label {
			&:before {
				opacity:1;
				top: 3px;
			}
		}
	}

.gform_footer {
	text-align: center !important;
}

.gform_wrapper .top_label li.gfield.gfield_error.gf_left_half {
	margin-top:0;
	margin-right:0;
}

.gform_wrapper li.gfield.gfield_error {
    background-color: #fff;
    margin-bottom: 0px!important;
    border-top: none;
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 0;

}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
	margin-top:0;
}

.validation_message {
	//display:none;
}

.gform_wrapper .gfield_error .gfield_label,
.gform_wrapper li.gfield_error div.ginput_complex.ginput_container label {
	color: #a40f2c !important;
}

.gform_wrapper div.validation_error {
	color: #a40f2c;
	font-size:18px;
	border-top: 2px solid #a40f2c;
    border-bottom: 2px solid #a40f2c;
}