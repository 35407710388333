.gform_button,
.btn-primary {
	background:#00A9A9;
	border:none;
	text-transform:uppercase;
	font-size:30px !important;
	color:#fff;
	border-radius:15px;
	padding:25px 70px;
}

.gform_button:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
	background:#008E8E;
}

.gform_button {
	font-family: 'CenturyGothic';
	font-size:21px !important;
	cursor:pointer
}

.btn:focus,
.btn:active {
	color:#fff;
	border:none;
}