.post-content{
  color: #666666;
  & > *{
    margin: 70px 0;
    @media screen and (max-width: 575px) {
      margin: 35px 0;
    }
  }
  .row{
    min-width: 100%;
  }
  .text-content{
    
  }
  blockquote{
    position: relative;
    padding: 30px 30px 30px 100px;
    font-size: 18px;
    background-color: #faf1f3;
    color: $brand-secondary;
    font-family: 'DINCondBold', Arial, Helvetica, sans-serif;
    border-radius: 10px;
    .speech-mark{
      position: absolute;
      left: 35px;
      top: 35px;
    }
    @media screen and (max-width: 575px) {
      padding: 20px 20px 20px 55px;
      .speech-mark {
        width: 25px;
        left: 20px;
        top: 25px;
      }
    }
    @media screen and (min-width: 575px) {
      margin-left: 60px;
      margin-right: 60px;
      font-size: 30px;
    }
  }
  .list{
    h3{
      color: $brand-secondary;
      font-family: 'DINCondBold', Arial, Helvetica, sans-serif;
      font-size: 30px;
    }
    ul{
      padding: 0;
      list-style-type: none;
      @media screen and (min-width: 768px) {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 100px;
        -moz-column-gap: 100px;
        column-gap: 100px;
      }
      li{
        display: inline-block;
        position: relative;
        font-size: 20px;
        font-family: 'DINCondBold', Arial, Helvetica, sans-serif;
        color: $brand-primary;
        padding: 5px 0 5px 25px;
        .bullet{
          position: absolute;
          left: 0;
          top: 16px;
        }
      }
    }
  }
  .cta{
    .btn-primary {
      padding: 10px 70px;
      font-size: 20px !important;
      @media screen and (max-width: 575px) {
        padding: 10px 20px;
        font-size: 16px !important; 
      }
    }
  }
}
.post-date{
  color: $brand-primary;
  font-family: 'DINCondBold', Arial, Helvetica, sans-serif;
  float: right;
  margin-left: 25px;
  img{
    vertical-align: text-top;
  }
  @media screen and (max-width: 768px){
    float: left;
    clear: left;
    margin-left: 0px;
  }
}
.post-lead-image{
  width: 100%; 
  text-align: center;
  img {
    border-radius: 10px;
    @media screen and (max-width: 960px) {
      border-radius: 0;
    }
  }
}

.post-related{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  
  h2{
    width: 100%;
  }
  
  &-item{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 50%;
    @media screen and (max-width: 768px){
      width: 100%;
      min-height: 150px;
    }
    float: left;
    padding: 15px;
    .inner {
      background: #e8e8e8;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
    }
  }
  &-image{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
    @media screen and (max-width: 768px) {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 35%;
      -ms-flex: 0 0 35%;
      flex: 0 0 35%;
      max-width: 35%;
    }
  }
  &-caption{
    padding: 25px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 70%;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
    h3{
      font-family: 'DINCondBold', Arial, Helvetica, sans-serif;
    }
    @media screen and (max-width: 768px) {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 65%;
      -ms-flex: 0 0 65%;
      flex: 0 0 65%;
      max-width: 65%;
      font-size: 0.8em;
      line-height: 1.2;
      padding: 10px 20px 10px 10px; 
      h3{
        font-size: 1.4em;
      }
    }
  }
}