.wtb {
  padding-top: 47px;
  position: relative;
  overflow: hidden;
  transition: all 0.25s;

  &.step_1 {
    .distributors {
      left: 300%;
      position: absolute;
    }
    .products {
      right: 0;
    }
    .distributor-step {
      opacity: 0.25;
    }

    @media screen and (max-width: 1200px) {
      padding-top: 180px;
    }

    @media screen and (max-width: 991px) {
      padding-top: 120px;
    }

    @media screen and (max-width: 768px) {
      padding-top: 100px;
    }
  }

  &.step_2 {

    padding-top: 237px;

    @media screen and (max-width: 450px) {
      padding-top: 200px;
    }
    .distributors {
      left: 0;
    }
    .products {
      right: 300%;
      position: absolute;
    }

    .wtb-filter {
      opacity: 0;
      height: 0;
    }

    .wtb-content>.row {
      margin-bottom: 60px;
    }
    .product-step {
      opacity: 0.25;
    }
  }

  &-steps {
    ul {
      text-align: center;
      padding: 0;
      margin-bottom:40px;
      li {
        background: #d8e1eb;
        color: #fff;
        font-family: 'DINCondBold';
        font-size: 37px;
        text-align: center;
        border-radius: 13px;
        padding: 10px 30px;
        width: 35%;
        max-width: 340px;
        display: inline-block;
        margin: 0 7%;
        position: relative;
        transition: all 0.25s;

        &.distributor-step {
          background: #033e85;
        }

        &.product-step {
          background: #a51e36;
        }

        &:hover {
          cursor: default;
        }

        @media screen and (max-width: 991px) {
          font-size: 25px;
          padding: 7px 20px;
        }

        @media screen and (max-width: 580px) {
          font-size: 20px;
          padding: 7px 8px;
          margin: 0 2%;
          width: 45%;
        } // &:after {
        //   content: '';
        //   display: inline-block;
        //   position: absolute;
        //   width: 73px;
        //   height: 17px;
        //   background-image: url('../images/arrow-right.png');
        //   background-repeat: no-repeat;
        //   right: -36%;
        //   right: calc(-50% + 46px);
        //   top: 41%;
        //   top: calc(50% - 9px);
        // }
        // &:last-of-type {
        //   margin-right: 0;
        //   &:after {
        //     content: none;
        //   }
        // }
      }
    }
  }

  &-title {
    text-align: center;
    font-size: 36px;
    color: #a51e36;
    padding-top: 15px;
    transition: all 0.5s;
    position: relative;
  }

  &-filter {
    position: relative;
    width: 100%;
    transition: all 0.5s;


    &-ttl {
      font-size: 30px;
      font-weight: 700;
      color: #033e85;
      position: relative;
      top: 3px;
    }

    &-btn {
      border-radius: 8px;
      background-color: rgb(3, 62, 133);
      font-family: 'DINCondBold';
      color: #fff !important;
      padding: 4px 90px 4px 20px;
      display: inline-block;
      margin-left: 10px;
      font-size: 20px;
      position: relative;
      transition: all 0.25s;

      &.__open:after {
        transform: rotate(180deg);
      }


      &:after {
        content: '';
        display: inline-block;
        width: 18px;
        height: 11px;
        background-image: url('../images/c-bottom.png');
        background-repeat: no-repeat;
        top: 15px;
        top: calc(50% - 5px);
        right: 20px;
        position: absolute;
        transition: all 0.25s;
      }

      &:hover {
        text-decoration: none;
        background: #a51e36;
      }
      &:active,
      &:focus {
        text-decoration: none;
      }
    }

    &-list {
      border-radius: 11px;
      background-color: rgb(3, 62, 133);
      position: absolute;
      padding: 20px 10px;
      width: 100%;
      list-style: none;
      margin: 0;
      column-count: 3;
      margin-top: 2px;
      display: none;
      z-index: 10;
      max-width: 420px;


      li {

        a {
          background:#fff;
          color: #033d85;
          font-weight:700;
          padding: 4px 10px 4px 15px;
          margin: 1px 0;
          width: 100%;
          display: inline-block;
          line-height: 1;
          border-radius: 6px;



          &:hover,
          &.mixitup-control-active {
            background: #af3848;
            color:#fff;
            text-decoration: none;
          }
        }
      }
    }
  }

  &-list {
    transition: all 0.5s;
    position: relative;
    width: 100%;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-top: 44px;
      justify-content: space-between;

      li {

        margin-bottom: 6px;


        @media screen and (min-width: 991px) {
          max-width: 24%;
          padding-left: 0;
          padding-right: 0;
        }


        @media screen and (max-width: 480px) {
          font-size: 14px;
          padding: 0 5px;
        }

        a {
          font-family: 'DINCondBold';
          font-size: 24px;
          color: #174d8f;
          background: #e9eef4;
          width: 100%;
          display: inline-block;
          border-radius: 11px;
          padding: 10px 25px;

          @media screen and (max-width: 1200px) {
            font-size: 20px;
            padding: 10px 20px;
          }

          @media screen and (max-width: 480px) {
            font-size: 14px;
            padding: 3px 10px;
          }

          &.__active,
          &:hover {
            text-decoration: none;
            color: #fff;
            background: #a51e36;
          }
        }
      }
    }
  }

  &-content {
    transition: all 0.5s;
    position: relative;
    &>.row {
      margin: 0;
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }

  &-contact {
    border-radius: 6px;
    background-color: rgb(246, 245, 240);
    padding: 50px 45px 25px 45px;
    color: #a51e36;
    font-size: 24px;

    .btn {
      margin-top: 70px;
      margin-left: auto;
      margin-right: auto;
      display: table;
    }
  }

  &-product {

    &-image {
      overflow: hidden;
      position: relative;
      border-radius: 6px;
      padding-left: 0;
      display: none;

      img {
        max-width: 375px;
        max-height: 375px;
      }

      .caption {
        position: absolute;
        bottom: 40px;
        // left: 15;
        left:0px;
        background-color: hsla(0, 0%, 100%, .8);
        padding: 0px 20px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        max-width: 350px;
        transition: all .6s ease 0s;
        font-family: 'DINCondBold';
        font-size: 35px;
        text-transform: uppercase;
        color: #a51e36;
      }
    }

    &-preview {
      position: absolute;
      top: 23px;
      display: none;

      .btn {
        font-size: 18px;
        font-family: 'DINCondBold';
        text-transform: none;
        padding: 4px 15px 4px 40px;
        margin-bottom: 15px;
        background-image: url('../images/arrow-left.png');
        background-repeat: no-repeat;
        background-position: 12px 50%;

        @media screen and (max-width: 450px) {
          font-size: 12px;
          padding: 5px 5px 5px 24px;
          background-size: 14px;
          background-position: 5px 50%;
          line-height: 1;
          margin-bottom: 20px;
        }
      }

      &-img {
        @extend .wtb-product-image;
        display: block;
        @media screen and (max-width: 450px) {
          width: 80%;
          max-height: 110px;
        }
        .caption {
          bottom: 13px;
          font-size: 24px;
          @media screen and (max-width: 450px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  &-distributor {
    &-logo {
      max-width: 100%;
      margin: 0 auto;
      display: table;
      height: auto;
      overflow: hidden;
      margin-bottom: 20px;

      img {
        max-width: 100%;
      }
    }

    &-desc {
      color: #6b6b6b;
    }
  }
}
