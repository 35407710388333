.single-range, single-recipes { 
	.banner-img .h-caption {
	    position: absolute;
	    top:auto;
	    bottom: 0;
	    background: rgba(255,255,255, 0.9);
	    padding: 20px 60px;
	    border-top-right-radius: 15px;
	    border-top-left-radius: 15px;
	    left: 50%;
	    -webkit-transform: translateX(-50%);
	    transform: translateX(-50%);
	}
	.banner-img h1 {
	    color: #a51e36;
	    font-size: 60px;
	    text-shadow: none;
	    padding: 0;
	}
	.n-facts {
		font-size:20px;
		table {
			margin-top:40px;
			th {
				color:#000;
				padding-bottom:20px;
			}
			tr {
				color:#033e85;
			}
			tr span {
				color:#a40f2c;
			}
			tr:nth-child(even) {background: #F3F1EA}
			tr:nth-child(odd) {background: #F6F5F0}
			td {
				padding:5px 0;
			}
		}
	}
	.select-distributor {
		text-align:center;
		p {
			color:#8e8e8e;
			font-size:18px;
			
		}
		.range-filter {
			margin:20px 0 50px 0;
		}
		.range-filter>ul>li {
			font-size:24px;
			text-transform:uppercase;
		}
		.range-filter>ul>li:hover {
			background:#174d8f;
		}
		.range-filter-submenu {
			width:100%;
			background:#174d8f;
			border-radius:10px;
			li a:before {
				display:none;
			}
		}
		.btn-primary {
			font-size:16px !important;
			padding:15px 50px;
			border-radius:10px;
		}
	}
}

.box {
	font-size:18px;
	color:#666666;
	line-height:22px;
	padding:40px 20px;
	border-radius:10px;
	h2 {
		color:#a50d2d;
		font-family: DINCond;
		font-size:18px;
		text-transform:uppercase;
		margin:0;
	}
	.fact-title {
		font-family: DINCondBold;
		font-size:24px;
		text-align:center;
		margin-bottom:10px;
	}
	.nutrition {
		font-family: DINCondBold;
		font-size:24px;
		color:#033e85;
		text-align:center;
	}
	p {
		margin:0;
	}
}

.yellow {
	background:#F6F5F0;
}

.blue {
	background:#E9EEF4;
}

.pink {
	background:#F8EFF1;
}
.turq {
	background:#E9FBFB;
}